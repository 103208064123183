import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0d536060 = () => interopDefault(import('../pages/adasok/index.vue' /* webpackChunkName: "pages/adasok/index" */))
const _1c2081f8 = () => interopDefault(import('../pages/kereses.vue' /* webpackChunkName: "pages/kereses" */))
const _87be6f10 = () => interopDefault(import('../pages/munkatarsak.vue' /* webpackChunkName: "pages/munkatarsak" */))
const _79106c11 = () => interopDefault(import('../pages/musorok/index.vue' /* webpackChunkName: "pages/musorok/index" */))
const _00a376b7 = () => interopDefault(import('../pages/musorvezetok/index.vue' /* webpackChunkName: "pages/musorvezetok/index" */))
const _d4492366 = () => interopDefault(import('../pages/player.vue' /* webpackChunkName: "pages/player" */))
const _05692084 = () => interopDefault(import('../pages/playlist.vue' /* webpackChunkName: "pages/playlist" */))
const _15c429fc = () => interopDefault(import('../pages/adasok/_adas.vue' /* webpackChunkName: "pages/adasok/_adas" */))
const _48a76040 = () => interopDefault(import('../pages/musorok/_musor.vue' /* webpackChunkName: "pages/musorok/_musor" */))
const _98b34722 = () => interopDefault(import('../pages/musorvezetok/_musorvezeto.vue' /* webpackChunkName: "pages/musorvezetok/_musorvezeto" */))
const _1aee2e96 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _19378d4e = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adasok",
    component: _0d536060,
    name: "adasok"
  }, {
    path: "/kereses",
    component: _1c2081f8,
    name: "kereses"
  }, {
    path: "/munkatarsak",
    component: _87be6f10,
    name: "munkatarsak"
  }, {
    path: "/musorok",
    component: _79106c11,
    name: "musorok"
  }, {
    path: "/musorvezetok",
    component: _00a376b7,
    name: "musorvezetok"
  }, {
    path: "/player",
    component: _d4492366,
    name: "player"
  }, {
    path: "/playlist",
    component: _05692084,
    name: "playlist"
  }, {
    path: "/adasok/:adas",
    component: _15c429fc,
    name: "adasok-adas"
  }, {
    path: "/musorok/:musor",
    component: _48a76040,
    name: "musorok-musor"
  }, {
    path: "/musorvezetok/:musorvezeto",
    component: _98b34722,
    name: "musorvezetok-musorvezeto"
  }, {
    path: "/",
    component: _1aee2e96,
    name: "index"
  }, {
    path: "/:slug",
    component: _19378d4e,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
